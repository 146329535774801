<template>
<div>
     <div class="row">
        <div class="col-12">
            <div class="card bg-gradient-magenta-orange">
                    <div class="card-body">
                     <div class="row">
                        <div class="col-lg-6 border-l">
                            <h4 class="mb-1 text-black">{{activeUser.username}}</h4>
                            <p class="text-muted">
                                <span class="badge badge-primary mr-2">
                                     <i class="mdi mdi-account-star"></i> Type: {{activeUser.role}}
                                </span>
                            </p>
                            <hr/>
                            <div class="text-left ">
                                <div class="table-responsive">
                                    <table class="table text-black table-borderless table-sm">
                                        <tbody>
                                            <tr>
                                                <th scope="row">Name:</th>
                                                <td class="text-black">{{activeUser.first_name}} {{activeUser.last_name}}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Phone:</th>
                                                <td class="text-black">{{activeUser.phone}}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Email:</th>
                                                <td class="text-black">{{activeUser.email}}</td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                            </div>

                        </div>
                         <div class="col-lg-6 d-flex align-items-center">
                            <div class="card mb-0 border-0 bg-transparent">
                                <img :src="absoluteUrl('/images/banners/learner-dash-bg.png')" alt="Image" class="card-img-top">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
     </div>
    <div class="row">
        <div class="col-sm-6 mb-3 mb-sm-o">
            <div class="card mb-0">
                <div class="card-body">
                    <h4 class="m-0 font-16 text-center">Free Groups</h4>
                    <h2 class="my-2 text-center" id="active-views-count">{{result.free_groups}}</h2>
                    <small class="text-muted m-0">
                        You can add one language to your list and learn for free
                    </small>
                </div>
            </div>
        </div>
        <div class="col-sm-6">
            <div class="card mb-0">
                <div class="card-body">
                    <h4 class="m-0 font-16 text-center">Subscribe Groups</h4>
                    <h2 class="my-2 text-center" id="active-views-count">{{result.subscribe_groups}}</h2>
                    <small class="text-muted m-0">
                        You can subscribe to a language, learn and get certified
                    </small>
                </div>
            </div>
        </div>
    </div>
    <div class="row mb-4 mt-3">
        <div class="col-12">
            <router-link to="/my-languages" class="btn mr-2 btn-primary" >My languages</router-link>
            <router-link to="/languages" class="btn btn-secondary" >All languages</router-link>
        </div>
    </div>
</div>
</template>

<script>

export default {

    data() {
        return {
            result:{
                free_groups: 0,
                subscribe_groups: 0
            }
        };
    },
    computed: {
        activeUser(){
            return this.$store.state.AppActiveUser
        },
    },
    methods: {
        initialize(){

        }
    },
    created() {
        this.$http.get('/analytics')
        .then((response) => {
            if(response.data.success){
                this.result = response.data.data
            }
        })
    },

};
</script>

